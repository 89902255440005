import { Controller } from "@hotwired/stimulus"
import { type TurboSubmitEndEvent } from "@hotwired/turbo"

export default class LoaderController extends Controller {
  static targets = ["loader"]
  declare readonly loaderTarget: HTMLElement

  start(): void {
    this.loaderTarget.hidden = false
  }

  stop(): void {
    this.loaderTarget.hidden = true
  }

  async stopIfError({ detail: { fetchResponse } }: TurboSubmitEndEvent): Promise<void> {
    const hasError =
      !fetchResponse || (await fetchResponse.responseHTML).includes("turboErrorStream")

    if (hasError) {
      this.stop()
    }
  }
}
