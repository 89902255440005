import { ActionEvent, Controller } from "@hotwired/stimulus"
import { fetchUrl } from "@/api/generic_fetch"

type AddressResult = {
  id: string
  address: string
}

type AddressDetails = {
  address1: string
  address2?: string
  company?: string
  city: string
  postcode: string
}

export default class extends Controller<HTMLElement> {
  static targets = [
    "addressResultTemplate",
    "postcodeLookup",
    "addressDetails",
    "addressResults",
    "alert",
    "postcodeNotFoundAlert",
    "addressNotFoundAlert",
    "enterAddressButton",
    "addressLine1Field",
    "addressLine2Field",
    "postcodeField",
    "cityField",
    "findAddressButton",
    "formInput",
    "requiredInput"
  ]

  declare readonly addressResultTemplateTarget: HTMLTemplateElement

  declare readonly formInputTargets: HTMLInputElement[]
  declare readonly requiredInputTargets: HTMLInputElement[]

  declare readonly postcodeLookupTarget: HTMLInputElement
  declare readonly addressResultsTarget: HTMLElement
  declare readonly addressDetailsTarget: HTMLFieldSetElement
  declare readonly enterAddressButtonTarget: HTMLButtonElement
  declare readonly findAddressButtonTarget: HTMLButtonElement

  declare readonly addressLine1FieldTarget: HTMLInputElement
  declare readonly addressLine2FieldTarget: HTMLInputElement
  declare readonly postcodeFieldTarget: HTMLInputElement
  declare readonly cityFieldTarget: HTMLInputElement

  declare readonly alertTargets: HTMLElement[]
  declare readonly postcodeNotFoundAlertTarget: HTMLElement
  declare readonly addressNotFoundAlertTarget: HTMLElement

  toggleVisible(show: boolean): void {
    this.element.hidden = !show

    this.formInputTargets.forEach((input) => {
      input.value = ""
      input.disabled = !show
    })

    this.requiredInputTargets.forEach((input) => {
      input.required = show
      input.dispatchEvent(new Event("resetValidity"))
    })
  }

  enterAddressManually(): void {
    this.showAddressForm()
    this.toggleResultsList(false)
    this.clearResults()
  }

  showAddressForm(): void {
    this.enterAddressButtonTarget.setAttribute("hidden", "true")
    this.addressDetailsTarget.removeAttribute("hidden")
  }

  clearResults(): void {
    this.addressResultsTarget.replaceChildren()
  }

  toggleResultsList(show: boolean): void {
    this.addressResultsTarget.parentElement!.hidden = !show
  }

  clearAlerts(): void {
    this.alertTargets.forEach((target) => (target.hidden = true))
  }

  async submitPostcode(): Promise<void> {
    this.findAddressButtonTarget.disabled = true

    const postcodeValue = this.postcodeLookupTarget.value

    try {
      const data = await fetchUrl(`/voucher_bookings/postcode_lookup?postcode=${postcodeValue}`)

      if (Array.isArray(data) && data.length > 0) {
        this.enterAddressButtonTarget.removeAttribute("hidden")
        this.addressDetailsTarget.setAttribute("hidden", "true")
        this.createAddressResults(data as AddressResult[])
      } else {
        this.postcodeNotFound()
      }
    } catch {
      this.postcodeNotFound()
    }

    this.findAddressButtonTarget.disabled = false
  }

  async retrieveAddress({ params }: ActionEvent): Promise<void> {
    this.addressResultsTarget.querySelectorAll("input").forEach((input) => (input.disabled = true))

    try {
      const data = await fetchUrl(`/voucher_bookings/retrieve_address?id=${params.id}`)

      this.enterAddressButtonTarget.setAttribute("hidden", "true")

      this.populateAddressFields(data as AddressDetails)
      this.toggleResultsList(false)
      this.clearResults()
      this.clearAlerts()
    } catch {
      this.addressNotFound()
    }
  }

  private postcodeNotFound(): void {
    this.postcodeNotFoundAlertTarget.removeAttribute("hidden")
    this.enterAddressManually()
  }

  private addressNotFound(): void {
    this.clearAlerts()

    this.addressNotFoundAlertTarget.removeAttribute("hidden")
    this.postcodeLookupTarget.value = ""

    this.enterAddressManually()
  }

  private populateAddressFields(address: AddressDetails): void {
    this.postcodeLookupTarget.value = ""
    this.addressDetailsTarget.removeAttribute("hidden")

    this.addressLine1FieldTarget.value = this.buildAddress1(address)
    this.addressLine2FieldTarget.value = address.address2 || ""
    this.cityFieldTarget.value = address.city
    this.postcodeFieldTarget.value = address.postcode
  }

  private buildAddress1(address: AddressDetails): string {
    if (address.company && address.company.trim().length > 0) {
      return `${address.company}, ${address.address1}`
    } else {
      return address.address1
    }
  }

  private createAddressResults(results: AddressResult[]): void {
    const fragment = document.createDocumentFragment()

    results.forEach((result) => {
      fragment.appendChild(this.createAddressResultFragment(result))
    })

    this.clearResults()
    this.addressResultsTarget.appendChild(fragment)
    this.toggleResultsList(true)
  }

  private createAddressResultFragment(result: AddressResult): DocumentFragment {
    const template = this.addressResultTemplateTarget.content.cloneNode(true) as DocumentFragment

    template.querySelector("span")!.innerText = result.address
    template.querySelector("input")!.setAttribute("data-physical-delivery-id-param", result.id)

    return template
  }
}
