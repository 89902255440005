import { Controller } from "@hotwired/stimulus"

import { type GiftMessageDetail } from "./gift_message_form_controller"

export default class GiftMessagePreviewController extends Controller {
  static targets = ["leftImage", "message", "recipientName", "rightImage", "senderName"]

  declare readonly leftImageTarget: HTMLImageElement
  declare readonly messageTarget: HTMLElement
  declare readonly recipientNameTarget: HTMLElement
  declare readonly rightImageTarget: HTMLImageElement
  declare readonly senderNameTarget: HTMLElement

  setAll({ message, recipientName, senderName, theme }: GiftMessageDetail): void {
    this.setMessage(message)
    this.setRecipientName(recipientName)
    this.setSenderName(senderName)
    this.setThemeImages(theme.leftImage, theme.rightImage)
  }

  setRecipientName(value: string): void {
    this.recipientNameTarget.textContent = value ? `To ${value}` : ""
  }

  setSenderName(value: string): void {
    this.senderNameTarget.textContent = value ? `From ${value}` : ""
  }

  setMessage(value: string): void {
    this.messageTarget.textContent = value
  }

  setThemeImages(leftImage: string, rightImage: string): void {
    this.leftImageTarget.src = leftImage
    this.rightImageTarget.src = rightImage

    this.leftImageTarget.hidden = !leftImage
    this.rightImageTarget.hidden = !rightImage
  }
}
