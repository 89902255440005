export default class Loader {
  private node: HTMLElement | null

  constructor() {
    this.node = document.getElementById("js-page-loader")
  }

  static hideAll(): void {
    document
      .querySelectorAll<HTMLElement>(
        ".ui-component-loader.variant\\:spinner, .ui-component-loader.variant\\:page"
      )
      .forEach((el) => (el.hidden = true))
  }

  start(): void {
    this.node?.removeAttribute("hidden")
  }

  stop(): void {
    this.node?.setAttribute("hidden", "")
  }
}
