import { ActionEvent, Controller } from "@hotwired/stimulus"
import PhysicalDeliveryController from "@/controllers/checkout/voucher_bookings/physical_delivery_controller"
import EvoucherDeliveryController from "@/controllers/checkout/voucher_bookings/evoucher_delivery_controller"
import { isEVoucher } from "@/helpers/delivery_options"
import type { DeliveryOption } from "@/helpers/delivery_options"
import * as Ga4 from "@/helpers/ga4_helper"
import { useBackForward } from "@/mixins/use_backforward"

export type DeliveryOptionEvent = CustomEvent<DeliveryOptionDetail>

export interface DeliveryOptionDetail {
  option: DeliveryOption
}

export default class DeliveryOptionsController extends Controller {
  static outlets = ["physical-delivery", "evoucher-delivery"]

  declare readonly physicalDeliveryOutlet: PhysicalDeliveryController
  declare readonly evoucherDeliveryOutlet: EvoucherDeliveryController

  static targets = ["radio"]
  static values = { selectedOption: Object }

  declare selectedOptionValue: DeliveryOption
  declare readonly radioTarget: HTMLInputElement
  declare readonly radioTargets: HTMLInputElement[]

  connect(): void {
    useBackForward(this)
  }

  backForward(): void {
    this.radioTargets.find((radio) => radio.checked)?.click()
  }

  selectDeliveryOption({ params }: ActionEvent): void {
    const isOutOfStock = params.outOfStock

    if (!isOutOfStock && !this.wasPreviouslySelected(params.option)) {
      Ga4.selectContentEvent("Delivery Options", params.option.name)
      this.selectedOptionValue = params.option as DeliveryOption
    }
  }

  selectedOptionValueChanged(currentOption: DeliveryOption, previousOption: DeliveryOption): void {
    const isCurrentEvoucher = isEVoucher(currentOption)
    const isPreviousEvoucher = isEVoucher(previousOption)
    const hasVoucherTypeChanged = isCurrentEvoucher !== isPreviousEvoucher

    this.radioTargets.find((target) => target.value === currentOption.name)!.checked = true

    if (hasVoucherTypeChanged) {
      this.evoucherDeliveryOutlet.toggleVisible(isCurrentEvoucher)
      this.physicalDeliveryOutlet.toggleVisible(!isCurrentEvoucher)
    }

    this.dispatch("setDeliveryOption", { detail: { option: currentOption } })
  }

  private wasPreviouslySelected(option: DeliveryOption): boolean {
    return option.supplierCode === this.selectedOptionValue.supplierCode
  }
}
