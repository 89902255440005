import "@/styles/ui_components/_disclosure-item.scss"

import { Controller } from "@hotwired/stimulus"
import { debounce } from "@/helpers/utils"

export default class extends Controller<HTMLDetailsElement> {
  static targets = ["cta", "summaryContent"]
  declare readonly ctaTarget: HTMLSpanElement
  declare readonly hasCtaTarget: boolean
  declare readonly summaryContentTarget: HTMLElement
  declare readonly hasSummaryContentTarget: boolean

  static values = {
    clamp: { type: Number, default: 0 },
    ctaClose: String,
    ctaOpen: String,
    expandOnce: { type: Boolean, default: false },
    scrollBlock: { type: String, default: "center" }
  }
  declare readonly clampValue: number
  declare readonly ctaCloseValue: string
  declare readonly ctaOpenValue: string
  declare readonly expandOnceValue: boolean
  declare readonly scrollBlockValue: "start" | "center"

  connect(): void {
    if (this.hasCtaTarget) {
      this.ctaTarget.textContent = this.ctaOpenValue
    }

    this.setSummaryMaxHeight()
    this.toggleCtaVisibility()
    this.toggleCtaVisibility = debounce(this.toggleCtaVisibility.bind(this), 100)
  }

  preventDefault(e: Event): void {
    e.preventDefault()
  }

  toggle(e: Event): void {
    e.preventDefault()

    this.element.open = !this.element.open

    this.setSummaryMaxHeight()
    this.scrollElementIntoView()

    if (this.hasCtaTarget) {
      if (this.expandOnceValue) {
        this.ctaTarget.remove()
      } else {
        this.ctaTarget.textContent = this.element.open ? this.ctaCloseValue : this.ctaOpenValue
      }
    }
  }

  toggleCtaVisibility(): void {
    if (!this.hasCtaTarget || !this.hasSummaryContentTarget) {
      return
    }

    this.ctaTarget.toggleAttribute(
      "hidden",
      this.summaryContentTarget.scrollHeight <= this.summaryContentTarget.clientHeight
    )
  }

  private scrollElementIntoView(): void {
    if (this.element.open && this.shouldScroll) {
      this.element.scrollIntoView({ behavior: "smooth", block: this.scrollBlockValue })
    }
  }

  // For iOS <= 16, which has a bug with line-clamp involving overflow:hidden and nested elements.
  // https://www2.webkit.org/show_bug.cgi?id=255487
  private setSummaryMaxHeight(): void {
    if (!this.hasSummaryContentTarget || this.clampValue === 0) {
      return
    }

    if (this.element.open) {
      this.summaryContentTarget.style.maxHeight = "none"
    } else {
      const summaryStyle = window.getComputedStyle(this.summaryContentTarget)
      const lineHeight = window.parseInt(summaryStyle.getPropertyValue("line-height"))
      this.summaryContentTarget.style.maxHeight = `${this.clampValue * lineHeight}px`
    }
  }

  private get shouldScroll(): boolean {
    // Disclosure items can open upwards or downwards depending on their position in viewport, so
    // check two things:
    // 1) Is both the top and bottom of the open element inside the viewport?
    // 2) Is the open element the topmost (i.e. visible) element? This check is
    // necessary in case of e.g. sticky headers/footers than might overlap the open element.

    const { bottom, left, right, top } = this.element.getBoundingClientRect()
    const isTopOutsideViewport = top <= 0
    const isBottomOutsideViewport = bottom >= window.innerHeight

    if (isTopOutsideViewport || isBottomOutsideViewport) {
      return true
    }

    // Get topmost elements at the corners of `this.element`, then check if the latter contains
    // the former; if it doesn't, that means something else is positioned on top of the element.
    const corners = [
      { x: left + 1, y: top + 1 },
      { x: right - 1, y: top + 1 },
      { x: left + 1, y: bottom - 1 },
      { x: right - 1, y: bottom - 1 }
    ]

    const isTopmostElement = corners.every(({ x, y }) =>
      this.element.contains(document.elementFromPoint(x, y))
    )

    return !isTopmostElement
  }
}
