import { Controller } from "@hotwired/stimulus"

type BackForward = (e: PageTransitionEvent) => void

const isBackForwardNavigation = (): boolean => {
  const navEntries = window.performance.getEntriesByType(
    "navigation"
  ) as PerformanceNavigationTiming[]

  return navEntries.at(0)?.type === "back_forward"
}

// Use this mixin to run operations on browser back/forward. In particular, Stimulus has problems
// detecting `checked` radios/checkboxes on back/forward, so this callback can be used to get the
// correct state of those controls. We use `pageshow` event here because:
// > Stimulus simply can't accurately read the checked property of an input element until after
// > the page has been painted.[0]
// [0] https://github.com/hotwired/stimulus/issues/328

const useBackForward = (controller: Controller & { backForward: BackForward }): void => {
  const controllerDisconnect = controller.disconnect.bind(controller)

  const backForward: BackForward = (e) => {
    if (isBackForwardNavigation()) {
      controller.backForward.call(controller, e)
    }
  }

  window.addEventListener("pageshow", backForward)

  Object.assign(controller, {
    disconnect: () => {
      window.removeEventListener("pageshow", backForward)
      controllerDisconnect()
    }
  })
}

export { useBackForward }
