import "@/styles/ui_components/_counter.scss"

interface QuantityDetail {
  count: number
}
export type QuantityEvent = CustomEvent<QuantityDetail>

import { Controller } from "@hotwired/stimulus"

const MIN_COUNT = 1
const STEP = 1

export default class extends Controller {
  static targets = ["countInput", "decrementButton", "incrementButton"]

  declare countInputTarget: HTMLInputElement
  declare readonly decrementButtonTarget: HTMLButtonElement
  declare readonly incrementButtonTarget: HTMLButtonElement

  static values = {
    count: { type: Number, default: MIN_COUNT }
  }

  declare countValue: number

  countValueChanged(): void {
    this.countInputTarget.value = this.countValue.toString()
    this.decrementButtonTarget.disabled = this.countValue - STEP < MIN_COUNT

    const detail: QuantityDetail = { count: this.countValue }
    this.dispatch("countChanged", { detail })
  }

  decrement(): void {
    this.countValue = Math.max(MIN_COUNT, this.countValue - STEP)
  }

  increment(): void {
    this.countValue = this.countValue + STEP
  }
}
