import { Controller } from "@hotwired/stimulus"

import airbrakeClient from "@/helpers/airbrake"

type ValidityStateMessage = [keyof ValidityState, string]

export default class InputValidityController extends Controller<HTMLLabelElement> {
  static targets = ["error", "input"]
  declare readonly errorTarget: HTMLElement
  declare readonly inputTarget: HTMLInputElement | HTMLTextAreaElement

  static values = {
    patternMismatchError: String,
    rangeUnderflowError: String,
    typeMismatchError: String,
    valueMissingError: String
  }
  declare readonly patternMismatchErrorValue: string
  declare readonly rangeUnderflowErrorValue: string
  declare readonly typeMismatchErrorValue: string
  declare readonly valueMissingErrorValue: string

  errorTargetConnected(error: HTMLElement): void {
    if (!error.hidden) {
      this.setAndReportCustomValidity(error.textContent!)
    }
  }

  clearError(): void {
    this.inputTarget.setCustomValidity("")
    this.setErrorMessage("")
  }

  reportValidity(): boolean {
    if (this.inputTarget.validity.valid) {
      this.setErrorMessage("")
      return true
    }

    const hasErrorMessage = this.validityMessages.some(([prop, message]) => {
      if (this.inputTarget.validity[prop] && !!message) {
        this.setErrorMessage(message)
        return true // We've found the first invalid property, stop processing
      }

      return false
    })

    if (!hasErrorMessage) {
      void airbrakeClient.notify({
        error: `Missing validation error message for input '${this.inputTarget.name}'`,
        params: this.inputTarget.validity
      })
    }

    return false
  }

  resetRangeUnderflowValidity(): void {
    const input = this.inputTarget as HTMLInputElement
    const { min } = input

    if (min) {
      input.min = input.value
      this.setAndReportCustomValidity("")
      input.min = min
    }
  }

  resetRequiredValidity(): void {
    if (this.inputTarget.required) {
      this.inputTarget.required = false
      this.setAndReportCustomValidity("")
      this.inputTarget.required = true
    }
  }

  private setAndReportCustomValidity(errorMessage: string): void {
    this.inputTarget.setCustomValidity(errorMessage)
    this.inputTarget.checkValidity()
    this.reportValidity()
  }

  private setErrorMessage(message: string): void {
    const isValid = message === ""

    this.errorTarget.textContent = message
    this.errorTarget.hidden = isValid

    this.element.classList.toggle("invalid", !isValid)
  }

  private get validityMessages(): ValidityStateMessage[] {
    // An input can be in more than one invalid state at a time. Process validity errors in the
    // order below, so the first matching validity property will be used to determine which error
    // to show.
    return [
      ["valueMissing", this.valueMissingErrorValue],
      ["patternMismatch", this.patternMismatchErrorValue],
      ["rangeUnderflow", this.rangeUnderflowErrorValue],
      ["typeMismatch", this.typeMismatchErrorValue],
      ["customError", this.inputTarget.validationMessage]
    ]
  }
}
