export interface DeliveryOption {
  name: string
  pricePence: number
  postagePricePence: number
  supplierCode: string
}

const GIFTCARD_CODE = "gcar"
const EVOUCHER_CODE = "evoucher"

type PostagePrice = number | null

const postagePrice = (deliveryOption: DeliveryOption, quantity: number): PostagePrice => {
  const { postagePricePence } = deliveryOption

  if (isEVoucher(deliveryOption)) {
    return null
  } else if (isGiftCard(deliveryOption)) {
    return postagePricePence
  } else {
    return postagePricePence * quantity
  }
}

const orderPrice = (
  voucherPrice: number,
  deliveryOption: DeliveryOption,
  quantity: number
): number => {
  return (voucherPrice + pricePence(deliveryOption)) * quantity
}

const totalPrice = (
  voucherPrice: number,
  deliveryOption: DeliveryOption,
  quantity: number
): number => {
  const postage = postagePrice(deliveryOption, quantity)
  const order = orderPrice(voucherPrice, deliveryOption, quantity)

  if (postage) {
    return order + postage
  } else {
    return order
  }
}

const isGiftCard = ({ supplierCode }: DeliveryOption): boolean => {
  return supplierCode === GIFTCARD_CODE
}

const isEVoucher = ({ supplierCode }: DeliveryOption): boolean => {
  return supplierCode === EVOUCHER_CODE
}

const pricePence = ({ pricePence }: DeliveryOption): number => {
  return pricePence
}

export { pricePence, orderPrice, postagePrice, totalPrice, isEVoucher, isGiftCard }
