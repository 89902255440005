import { type ActionEvent, Controller } from "@hotwired/stimulus"

export default class InputUtilsController extends Controller<HTMLInputElement> {
  limitChars({ params: { maxChars } }: ActionEvent): void {
    this.element.value = this.element.value.substring(0, maxChars)
  }

  removeInvalidChars({ params: { invalidCharsRegexp } }: ActionEvent): void {
    this.element.value = this.element.value.replace(new RegExp(invalidCharsRegexp, "g"), "")
  }

  removeNonDigits(): void {
    this.element.value = this.element.value.replace(/[^\d]/g, "")
  }

  removeWhitespace(): void {
    this.element.value = this.element.value.replace(/\s+/g, "")
  }

  selectAll(): void {
    this.element.select()
  }

  toUpperCase(): void {
    this.element.value = this.element.value.toUpperCase()
  }
}
