import { Controller } from "@hotwired/stimulus"

import { BREAKPOINTS } from "@/const/breakpoints"
import { debounce } from "@/helpers/utils"

export default class CollapsibleItemsController extends Controller {
  static targets = ["cta", "ctaCollapse", "ctaExpand", "item"]

  declare readonly ctaTarget: HTMLButtonElement
  declare readonly ctaCollapseTarget: HTMLSpanElement
  declare readonly ctaExpandTarget: HTMLSpanElement
  declare readonly itemTargets: HTMLElement[]

  static values = {
    collapsed: Boolean,
    maxVisibleDesktop: Number,
    maxVisibleMobile: Number
  }

  declare collapsedValue: boolean
  declare readonly maxVisibleDesktopValue: number
  declare readonly maxVisibleMobileValue: number

  initialize(): void {
    // No debounce on initialize
    this.setCollapsedItems()

    this.setCollapsedItems = debounce(this.setCollapsedItems.bind(this), 500)
  }

  setCollapsedItems(): void {
    this.ctaTarget.hidden = this.itemTargets.length <= this.maxVisibleItems
    this.toggleItemVisibility()
  }

  toggle(): void {
    this.collapsedValue = !this.collapsedValue
    this.ctaExpandTarget.hidden = !this.collapsedValue
    this.ctaCollapseTarget.hidden = this.collapsedValue

    this.toggleItemVisibility()
    this.scrollToItem()
  }

  private get maxVisibleItems(): number {
    return window.innerWidth <= BREAKPOINTS.tabletPortrait ||
      window.innerHeight <= BREAKPOINTS.minMobileHeight
      ? this.maxVisibleMobileValue
      : this.maxVisibleDesktopValue
  }

  private scrollToItem(): void {
    this.itemTargets
      .at(this.collapsedValue ? 0 : this.maxVisibleItems)!
      .scrollIntoView({ behavior: "smooth" })
  }

  private toggleItemVisibility(): void {
    this.itemTargets.forEach((item, index) => {
      // Items less than max are always visible, only toggle those greater than max
      if (index < this.maxVisibleItems) {
        item.hidden = false
      } else {
        item.hidden = this.collapsedValue
      }
    })
  }
}
