import { Controller } from "@hotwired/stimulus"

export default class WindowNavigationController extends Controller {
  back(): void {
    window.history.back()
  }

  reload(): void {
    window.location.reload()
  }
}
