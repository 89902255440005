import { Controller } from "@hotwired/stimulus"

import { errorShownEvent } from "@/helpers/ga4_helper"

export default class ErrorTrackingController extends Controller<HTMLElement> {
  errorObserver = new MutationObserver((mutations) => {
    mutations.every(({ attributeName, type }) => {
      const hasContentChanged = type === "characterData" || type === "childList"
      const hasVisibilityChanged = type === "attributes" && attributeName === "hidden"

      if (hasContentChanged || hasVisibilityChanged) {
        this.pushErrorEventIfVisible()

        // Break so we don't fire twice (e.g. if one mutation is a content change and another is
        // a visibility change)
        return false
      }

      return true
    })
  })

  connect(): void {
    this.pushErrorEventIfVisible()

    this.errorObserver.observe(this.element, {
      attributes: true, // Watch for `hidden` attribute changes
      characterData: true, // Watch for error text content changes
      childList: true, // Watch for Turbo error stream element changes
      subtree: true
    })
  }

  disconnect(): void {
    this.errorObserver.disconnect()
  }

  private pushErrorEventIfVisible(): void {
    if (!this.element.hidden) {
      errorShownEvent("form_errors", this.element.innerText, this.errorId)
    }
  }

  private get errorId(): string {
    const inputName = this.element
      .closest("label")
      ?.querySelector("input, textarea")
      ?.getAttribute("name")
    const fieldsetName = this.element.closest("fieldset")?.getAttribute("name")

    return inputName || fieldsetName || this.element.id
  }
}
