const GB_LOCALE = "en-GB" as Intl.LocalesArgument

const intlDateTimeFormat = (date: Date, format: Intl.DateTimeFormatOptions): string => {
  return new Intl.DateTimeFormat(GB_LOCALE, format).format(date)
}

const formatDMMM = (date: Date): string => {
  return intlDateTimeFormat(date, {
    day: "numeric",
    month: "short"
  })
}

const formatDDMMYYYY = (date: Date): string => {
  return intlDateTimeFormat(date, {
    day: "2-digit",
    month: "2-digit",
    year: "numeric"
  })
}

const formatEEEDMMMYYYY = (date: Date): string => {
  return intlDateTimeFormat(date, {
    day: "numeric",
    month: "short",
    weekday: "short",
    year: "numeric"
  })
}

export { formatDMMM, formatDDMMYYYY, formatEEEDMMMYYYY }
