import { Controller } from "@hotwired/stimulus"

import PhysicalDeliveryController from "@/controllers/checkout/voucher_bookings/physical_delivery_controller"

export default class CheckoutFormController extends Controller<HTMLFormElement> {
  static outlets = ["physical-delivery"]

  declare readonly physicalDeliveryOutlet: PhysicalDeliveryController
  declare readonly hasPhysicalDeliveryOutlet: boolean

  connect(): void {
    this.enableSubmit()
  }

  submit(event: Event): void {
    this.dispatch("validate")

    if (this.checkValidity) {
      this.serverErrorNotice.hidden = true
      this.physicalDeliveryOutletPresence?.clearAlerts()
      this.toggleSubmit(false)
    } else {
      event.preventDefault()
      this.serverErrorNotice.hidden = false

      // Make sure errors in address details are visible
      this.physicalDeliveryOutletPresence?.showAddressForm()

      this.scrollToFirstError()
    }
  }

  enableSubmit(): void {
    this.toggleSubmit(true)
  }

  private get checkValidity(): boolean {
    return this.element.checkValidity() && this.checkHiddenFieldsValid
  }

  private get checkHiddenFieldsValid(): boolean {
    const hiddenInputs = Array.from(
      this.element.querySelectorAll("input[type=hidden][name=form_validity]")
    ) as HTMLInputElement[]

    return hiddenInputs.every((hiddenInput) => hiddenInput.validity.valid)
  }

  private get physicalDeliveryOutletPresence(): PhysicalDeliveryController | null {
    return this.hasPhysicalDeliveryOutlet ? this.physicalDeliveryOutlet : null
  }

  private scrollToFirstError(): void {
    // Note: Selecting by CSS class here isn't ideal. We'd prefer filtering `inputValidityOutlets`
    // to find visible errors and scroll to the first of those, but currently treatments/meals do
    // not use that controller for validations.
    window.requestAnimationFrame(() => {
      this.element
        .querySelector(".utils\\:error:not([hidden])")
        ?.scrollIntoView({ behavior: "smooth", block: "center" })
    })
  }

  private get serverErrorNotice(): HTMLElement {
    return document.getElementById("turbo-form-error-notice")!
  }

  private toggleSubmit(enabled: boolean): void {
    const button = document.querySelector<HTMLButtonElement>(
      "button[form=checkout-form][type=submit]"
    )!
    const icon = button.querySelector("i.far")!

    button.disabled = !enabled
    icon.classList.toggle("fa-arrow-right", enabled)
    icon.classList.toggle("fa-spinner", !enabled)
    icon.classList.toggle("fa-spin", !enabled)
  }
}
