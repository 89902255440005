import { Controller } from "@hotwired/stimulus"

import { debounce } from "@/helpers/utils"

export default class extends Controller<HTMLFormElement> {
  static targets = ["applicableVoucherPrice", "code", "turboErrorStream"]

  declare readonly applicableVoucherPriceTarget: HTMLInputElement
  declare readonly codeTarget: HTMLInputElement

  initialize(): void {
    this.applyPromoToVoucher = debounce(this.applyPromoToVoucher.bind(this), 500)
  }

  applyPromoToVoucher(code: string): void {
    this.codeTarget.value = code
    this.element.requestSubmit()
  }

  resetForm(): void {
    this.element.reset()
    this.codeTarget.dispatchEvent(new Event("resetValidity"))
  }

  turboErrorStreamTargetConnected(): void {
    this.dispatch("discountError")
  }

  validate(e: Event): void {
    if (!this.element.checkValidity()) {
      e.preventDefault()
    }
  }

  updateApplicableVoucherPrice(pricePounds: number): void {
    this.applicableVoucherPriceTarget.value = pricePounds.toString()
  }
}
