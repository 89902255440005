import { Controller } from "@hotwired/stimulus"
import VoucherPaymentSummaryController from "./payment_summary_controller"

export default class VoucherAmountController extends Controller {
  static outlets = ["voucher-payment-summary"]
  declare readonly voucherPaymentSummaryOutlet: VoucherPaymentSummaryController

  static targets = ["radioOption", "customInput"]

  declare readonly radioOptionTargets: HTMLInputElement[]
  declare readonly customInputTarget: HTMLInputElement

  handleSelectdableAmountOption(): void {
    this.clearCustomAmount()
    this.updateAmounts()
  }

  handleCustomAmountOption(): void {
    this.radioOptionTargets.forEach((radio) => (radio.checked = false))
    this.customInputTarget.required = true
    this.updateAmounts()
  }

  private clearCustomAmount(): void {
    this.customInputTarget.required = false
    this.customInputTarget.value = ""
    this.customInputTarget.dispatchEvent(new Event("resetValidity"))
  }

  private get selectedAmount(): number {
    const selectedRadio = this.radioOptionTargets.find((radio) => radio.checked)

    return +(selectedRadio?.value || this.customInputTarget.value)
  }

  private updateAmoutQueryParam(): void {
    const url = new URL(window.location.href)
    const params = url.searchParams

    params.set("amount", this.selectedAmount.toString())
    url.search = params.toString()
    window.history.replaceState({}, "", url.toString())
  }

  private updateAmounts(): void {
    // updates voucher amount in Voucher Details section
    Array.from(document.querySelectorAll(".js-voucher-amount-value")).forEach((voucherAmount) => {
      ;(voucherAmount as HTMLElement).innerText = `£${this.selectedAmount}`
    })

    this.updateAmoutQueryParam()
    this.voucherPaymentSummaryOutlet.setVoucherPrice(this.selectedAmount * 100)
  }
}
