import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = { url: String }

  declare readonly urlValue: string

  connect(): void {
    window.location.assign(this.urlValue)
    this.element.remove()
  }
}
