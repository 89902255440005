import { Controller } from "@hotwired/stimulus"

import ModalController from "@/controllers/modal_controller"
import GiftMessagePreviewController from "@/controllers/checkout/gift_message_preview_controller"
import GiftMessageSectionController from "@/controllers/checkout/gift_message_section_controller"
import { useBackForward } from "@/mixins/use_backforward"

export interface GiftMessageDetail {
  message: string
  recipientName: string
  senderName: string
  theme: GiftMessageTheme
}

interface GiftMessageTheme {
  name: string
  leftImage: string
  rightImage: string
}

export default class GiftMessageFormController extends Controller {
  static outlets = ["gift-message-modal", "gift-message-preview", "gift-message-section"]
  declare readonly giftMessageModalOutlet: ModalController
  declare readonly giftMessagePreviewOutlet: GiftMessagePreviewController
  declare readonly giftMessageSectionOutlet: GiftMessageSectionController

  static targets = [
    "input",
    "leftImageInput",
    "messageInput",
    "recipientInput",
    "remainingCharacters",
    "rightImageInput",
    "senderInput",
    "themeRadio"
  ]
  declare readonly inputTargets: HTMLInputElement[]
  declare readonly leftImageInputTarget: HTMLInputElement
  declare readonly messageInputTarget: HTMLInputElement
  declare readonly recipientInputTarget: HTMLInputElement
  declare readonly remainingCharactersTarget: HTMLSpanElement
  declare readonly rightImageInputTarget: HTMLInputElement
  declare readonly senderInputTarget: HTMLInputElement
  declare readonly themeRadioTargets: HTMLInputElement[]

  static values = {
    savedDetails: Object,
    themes: Array
  }
  declare savedDetailsValue: GiftMessageDetail
  declare readonly themesValue: GiftMessageTheme[]

  connect(): void {
    this.toggleThemeRadios()
    useBackForward(this)
  }

  backForward(): void {
    if (this.isAllInputsEmpty) {
      this.removeMessage()
    } else {
      this.saveMessage()
      this.giftMessagePreviewOutlet.setAll(this.savedDetailsValue)
    }
  }

  clearForm(): void {
    this.setInputs(this.emptyDetails)
  }

  handleMessageInput(): void {
    this.giftMessagePreviewOutlet.setMessage(this.messageInputTarget.value.trim())
    this.updateHint()
    this.toggleThemeRadios()
  }

  handleSenderNameInput(): void {
    this.giftMessagePreviewOutlet.setSenderName(this.senderInputTarget.value.trim())
    this.toggleThemeRadios()
  }

  handleRecipientNameInput(): void {
    this.giftMessagePreviewOutlet.setRecipientName(this.recipientInputTarget.value.trim())
    this.toggleThemeRadios()
  }

  removeMessage(): void {
    this.savedDetailsValue = this.emptyDetails
    this.clearForm()
  }

  resetForm(): void {
    this.setInputs(this.savedDetailsValue)
  }

  themeChanged({ currentTarget }: IEvent<HTMLInputElement>): void {
    const { leftImage, rightImage } = this.themesValue.find(
      (theme) => theme.name === currentTarget.value
    )!

    this.leftImageInputTarget.value = leftImage
    this.rightImageInputTarget.value = rightImage

    this.leftImageInputTarget.hidden = !leftImage
    this.rightImageInputTarget.hidden = !rightImage

    this.giftMessagePreviewOutlet.setThemeImages(leftImage, rightImage)
  }

  validate(): void {
    if (this.isAllInputsEmpty) {
      this.giftMessageSectionOutlet.removeMessage()
    } else {
      this.saveMessage()
    }

    this.giftMessageModalOutlet.close()
  }

  private get defaultTheme(): GiftMessageTheme {
    return this.themesValue.at(0)!
  }

  private get emptyDetails(): GiftMessageDetail {
    return {
      message: "",
      recipientName: "",
      senderName: "",
      theme: this.defaultTheme
    }
  }

  private get isAllInputsEmpty(): boolean {
    return this.inputTargets.every((input) => !input.value)
  }

  private saveMessage(): void {
    // Before saving details, trigger currently-checked radio callback so theme images are set
    this.checkedThemeRadio.click()

    this.savedDetailsValue = {
      message: this.messageInputTarget.value.trim(),
      recipientName: this.recipientInputTarget.value.trim(),
      senderName: this.senderInputTarget.value.trim(),
      theme: {
        name: this.checkedThemeRadio.value,
        leftImage: this.leftImageInputTarget.value,
        rightImage: this.rightImageInputTarget.value
      }
    }

    this.dispatch("saveMessage", { detail: this.savedDetailsValue })
  }

  private get maxLength(): number {
    return this.messageInputTarget.maxLength
  }

  private get checkedThemeRadio(): HTMLInputElement {
    return this.themeRadioTargets.find((radio) => radio.checked)!
  }

  private updateHint(): void {
    const remaining = this.maxLength - this.messageInputTarget.value.length
    this.remainingCharactersTarget.textContent = Math.max(0, remaining).toString()
  }

  private setInputs(detail: GiftMessageDetail): void {
    this.recipientInputTarget.value = detail.recipientName
    this.senderInputTarget.value = detail.senderName
    this.messageInputTarget.value = detail.message
    this.themeRadioTargets.find((radio) => radio.value === detail.theme.name)!.click()

    this.toggleThemeRadios()
    this.giftMessagePreviewOutlet.setAll(detail)
    this.updateHint()
  }

  private toggleThemeRadios(): void {
    const [defaultRadio, ...otherRadios] = this.themeRadioTargets

    if (this.isAllInputsEmpty) {
      defaultRadio.click()
    }

    otherRadios.forEach((radio) => (radio.disabled = this.isAllInputsEmpty))
  }
}
