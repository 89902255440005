import { ActionEvent, Controller } from "@hotwired/stimulus"
import DeliveryOptionsController from "./delivery_options_controller"

// Delivery option details modal is rendered outside of the DeliveryOptionsController,
// we can't call its methods directly inside the modal.
// Therefore, we need to use a separate controller to update the selected delivery option from the modal.
export default class DeliveryOptionsModalController extends Controller {
  static outlets = ["delivery-options"]

  declare readonly deliveryOptionsOutlet: DeliveryOptionsController

  selectedOption(event: ActionEvent): void {
    const { option } = event.params

    // Pass the outOfStock parameter as false
    // because the select button will not be rendered in the modal
    // if the delivery option is in stock.
    this.deliveryOptionsOutlet.selectDeliveryOption({
      ...event,
      params: { option, outOfStock: false }
    })
  }
}
