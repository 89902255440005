import { Controller } from "@hotwired/stimulus"

import ElmCarousel from "@/components/elm_carousel"
import { loadBackgroundImages } from "@/helpers/image_loader"

export default class extends Controller {
  static targets = ["elmNode"]
  declare readonly elmNodeTarget: HTMLDivElement

  static values = { flags: Object }
  declare readonly flagsValue: { [key: number]: string }

  initialize(): void {
    this.loadImages = this.loadImages.bind(this)
  }

  elmNodeTargetConnected(): void {
    new ElmCarousel({
      node: this.elmNodeTarget,
      flags: this.flagsValue,
      ports: {
        carouselActivated: this.loadImages.bind(this),
        ready: this.loadImages.bind(this)
      }
    })
  }

  private loadImages(): void {
    loadBackgroundImages(this.element.querySelectorAll("[data-img]"))
  }
}
