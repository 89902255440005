const formatPrice = (value: number): string => {
  return Intl.NumberFormat("en-GB", {
    style: "currency",
    currency: "GBP",
    maximumFractionDigits: 2,
    trailingZeroDisplay: "stripIfInteger"
  }).format(value)
}

export { formatPrice }
